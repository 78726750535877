import axios from "@axios";
function beetween(a, b) {
  var min = Math.min.apply(Math, [a, b]),
    max = Math.max.apply(Math, [a, b]);
  return this > min && this < max;
}
export default {
  namespaced: true,
  stateFactory: true,
  state: {
    animalsEar: [],
  },
  getters: {},
  mutations: {
    SET_ANIMALSEAR_TAG(state, payload) {
      payload.map((x) =>
        state.animalsEar.push({ value: x.id, label: x.earingno })
      );
    },
  },
  actions: {
    fetchAnimals(ctx, queryParams) {
      var page = queryParams.perPage * queryParams.page;
      var count = 1;
      var totalCount = 0;
      var offSet = queryParams.perPage * (queryParams.page - 1);
      return new Promise((resolve, reject) => {
        axios
          .get("Companies/CompanyAnimals")
          .then((response) => {
            ctx.commit("SET_ANIMALSEAR_TAG", response.data);
            var res = response.data.filter((x) => {
              var isOk = true;

              if (queryParams.type)
                isOk = x.animalType.includes(queryParams.type);
              if (queryParams.gender)
                isOk = x.gendername.includes(queryParams.gender);

              var ear = x.earingno
                .toString()
                .toLowerCase()
                .includes(queryParams.q.toLowerCase());
              if (count > offSet && count < page && ear) {
                isOk = true;
              } else isOk = false;
              count++;

              return isOk;
            });

            response.data = res;
            response.data.list = count;
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteAnimal(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/Animals/Delete/" + queryParams.id)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchAnimal(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/Animals/GetAnimalByid/${id}`)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    addBirth(ctx, animalData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/Animals/AddBirth", animalData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
