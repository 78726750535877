import { getUserData } from "@/auth/utils";
import axios from "@axios";
import VueI18n from "@/libs/i18n";

export default {
  namespaced: true,
  stateFactory: true,
  state: {
    userData: [],
    selectedUser: {},
  },
  getters: {},
  mutations: {
    SET_USERS(state, payload) {
      state.userData = payload;
    },
    SET_USER(state, payload) {
      state.selectedUser = payload;
    },
  },
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/Users/GetAllUsers/", {
            headers: {
              culinfo: getUserData().culinfo,
            },
          })
          .then((response) => {
            ctx.commit("SET_USERS", response.data.resultdata);
            if (response.data.status != "NULL") {
              var res = response.data.resultdata;

              response.data = res;
            }

            return resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    async getAsIf(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post("/asIf", id.toString(), {
            headers: {
              culinfo: getUserData().culinfo,
            },
          })
          .then((response) => {
            ctx.commit("SET_USER", response.data.resultdata);

            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    async extendCompanyModule(
      ctx,
      {
        companyid,
        days,
        pricepaid,
        maxformula,
        maxplant,
        hasdairy,
        hasbeef,
        hassheep,
        hasgoat,
      }
    ) {
      return new Promise((resolve, reject) => {
        axios
          .put("/Companies/ExtendCompanyModule/0", {
            companyid: companyid,
            days: days,
            pricepaid: pricepaid,
            maxformula: maxformula,
            maxplant: maxplant,
            hasdairy,
            hasbeef,
            hassheep,
            hasgoat,
          })
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    async getRenewPrice(
      ctx,
      {
        userid,
        priceType,
        hasdairy,
        hasbeef,
        hassheep,
        hasgoat,
        maxformula,
        maxplant,
      }
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get("/Users/GetRenewPrice/" + userid, {
            headers: {
              culinfo: getUserData().culinfo,
              priceType: priceType,
              hasdairy: hasdairy,
              hasbeef: hasbeef,
              hassheep: hassheep,
              hasgoat: hasgoat,
              maxformula: maxformula,
              maxplant: maxplant,
            },
          })
          .then((response) => {
            return resolve(response.data.resultdata[0] || []);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
